<template>
	<section id="administrations" class="list-administrations size-16">
		<b-row style="justify-content: space-between" class="mb-2 align-center">
		    <b-col>
		    	<h2>Administrations</h2>
		    </b-col>
		    <b-col v-if="tabIndex_parent == 2 || tabIndex_parent == 3 || tabIndex_parent == 4">
		    	<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
			      	<b-form-group class="mb-0">
				        <b-input-group class="input-group-merge">
					        <b-input-group-prepend is-text>
					            <feather-icon icon="SearchIcon" size="17" />
					        </b-input-group-prepend>
					        <b-form-input
					            placeholder="Search"
					            @input="searchValue"
					        />
				        </b-input-group>
			      	</b-form-group>
			    </div>
		    </b-col>
		</b-row>
		<b-row class="list-item">
		    <b-col md="12"> 
		    	<b-tabs card class="caption-add table-custom" v-model="tabIndex_parent">
		            <b-tab title="System Logs" class="list-tab tab-child">
		            	<b-tabs card class="caption-add table-custom">
			            	<b-tab title="Activity Log">
			            		<b-row class="mb-2">
				            		<b-col xl=7>
				            			<b-row class="options_list size-16">
						            		<b-col xl="4" style="padding-left: 13px">
											    <v-select
											    	v-model="selected_staff"
			                                      	label="full_name"                   
			                                      	:options="staff_options"
			                                      	placeholder="Staff"
			                                      	class="black-child-im"
			                                    />
						            		</b-col>
						            		<b-col xl="4">
											    <v-select
			                                      v-model="selected_module"
			                                      label="group_name"
			                                      :options="module_options"
			                                      placeholder="Module"
			                                      class="black-child-im"
			                                    />
						            		</b-col>	
						            		<b-col xl="4">
											     <v-select
			                                      v-model="selected_action"
			                                      label="title"
			                                      :options="staff_action"
			                                      placeholder="Action"
			                                      class="black-child-im"
			                                    />
						            		</b-col>			            		
						            	</b-row>	
				            		</b-col>
				            		<b-col xl=5>
				            			<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
									      	<b-form-group class="mb-0">
										        <b-input-group class="input-group-merge">
											        <b-input-group-prepend is-text>
											            <feather-icon icon="SearchIcon" size="17" />
											        </b-input-group-prepend>
											        <b-form-input
											        	type="text"
											            placeholder="Search"
											            @input="searchActivityLog"
											        />
										        </b-input-group>
									      	</b-form-group>
									    </div>
				            		</b-col>			            	
					            </b-row>
							    <template v-for="(item, index) in boxs">
							    	<b-card class="mb-1 py-1 card-body-p0 ">
						            	<b-row>
						            		<b-col xl="2" lg="3" class="date_added">{{ item.created_at }}</b-col>
						            		<b-col xl="2" lg="3">{{ item.staff }}</b-col>
						            		<b-col xl="8" lg="6">
						            			{{ item.content }}<br>
						            			<small style="color: #B1B1B1">{{ item.breadscrumb }}</small>
						            		</b-col>
						            	</b-row>
								    </b-card>
							    </template>
								<div v-if="this.total_activity_log > 0" class="paging-cus relative">
									<b-pagination
										v-model="currentPage_activity_log"
										:total-rows="total_activity_log"
										:per-page="perPage_activity_log"
										aria-controls="custom-table"
										first-number
										last-number
										class="just-center"
									></b-pagination>
									<div class="limit-per">
										<select v-model="selected_activity_log" class="cursor-pointer">
											<option v-for="limit in limitpage_activity_log">{{ limit }}</option>
										</select>			  
									</div>
								</div>  
							    <template v-if="boxs.length == 0">
							    	<b-card v-if="searchInput">
							    		<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data found</h4>
							    	</b-card>	
							    	<b-card v-else>
							    		<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
							    	</b-card>							    	
							    </template>
			            	</b-tab>
			            	<b-tab title="Access Log">
			            		<b-row class="mb-2">
				            		<b-col xl=7>
				            			<b-row class="options_list size-16">
						            		<b-col xl="4" style="padding-left: 13px">
			                                    <!-- <b-form-select v-model="selected_access" :options="optionsAccess">
			                                    	
			                                    </b-form-select> -->
												<v-select
											    	v-model="selected_access"
			                                      	label="full_name"                   
			                                      	:options="optionsAccess"
			                                      	placeholder="Staff"
			                                      	class="black-child-im"
			                                    />
						            		</b-col>	            		
						            	</b-row>	
				            		</b-col>
				            		<b-col xl=5>
				            			<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
									      	<b-form-group class="mb-0">
										        <b-input-group class="input-group-merge">
											        <b-input-group-prepend is-text>
											            <feather-icon icon="SearchIcon" size="17" />
											        </b-input-group-prepend>
											        <b-form-input
											        	type="text"
											            placeholder="Search"
											            @input="searchAccessLog"
											        />
										        </b-input-group>
									      	</b-form-group>
									    </div>
				            		</b-col>			            	
					            </b-row>
					            <template v-for="(item, index) in boxsAccessLog">
							    	<b-card class="mb-1 py-1 card-body-p0 ">
						            	<b-row>
						            		<b-col xl="2" lg="3" class="date_added">{{ item.created_format }}</b-col>
						            		<b-col xl="2" lg="3">{{ item.full_name }}</b-col>
						            		<b-col xl="8" lg="6">
						            			{{ item.content }}<br>
						            			<small style="color: #B1B1B1">{{ item.breadscrumb }}</small>
						            		</b-col>
						            	</b-row>
								    </b-card>
							    </template>
							    <template v-if="boxsAccessLog.length == 0">
							    	<b-card v-if="searchInputAccess">
							    		<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data found</h4>
							    	</b-card>	
							    	<b-card v-else>
							    		<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
							    	</b-card>				    	
							    </template>
			            	</b-tab>
			            </b-tabs>
		            </b-tab>   
		            <b-tab title="Content Info" class="tab-child tab-pt-0" ref="contentInfo_ref">	
		            	<b-tabs card class="caption-add table-custom" v-model="tabIndex_info">
		            		<b-tab title="Useful Info(P)">
		            			<b-card class="px-table-0 w-50-percent-cl-2">
		            				<div class="text-right mr-2 my-2">

		            					<template v-if="userData">
						                    <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important" @click='addUserful'>
						                        Add New
						                    </b-button>
						                </template>
						                <template v-else>
						                	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>Add New</b-button>
						                </template>
					                </div>
					                <b-table small :fields="useful_fields" :items="useful" tbody-tr-class="size-14 table-row cursor-pointer" id="userful-table" show-empty @row-clicked="userful_detail">
					                	<template #head(id)="data">
								    		<span>{{ data.label }}</span>
								    	</template>
								    	<template #head(title)="data">
								    		<span>{{ data.label }}</span>
								    	</template>	
					                	<template #head()="data">
								    		<span class="d-block text-center">{{ data.label }}</span>
								    	</template>	
								    	<template #cell(id)="data">
											{{ data.item.id }}
										</template>	
										<template #head(title)="data">
											<span class="">{{ data.label }}</span>
										</template>
										<template #cell(title)="data">
											{{ data.item.title }}
										</template>
										<template #cell(created_at)="data">
											<div class="d-block text-center">
												{{ data.item.created_at | formatDatebyMoment("YYYY-MM-DD hh:mm:ss", "DD/MM/YY hh:mm:ss") }} 
											</div>
										</template>		
										<template #cell(status)="data">
											<div class="d-block text-center">
												<span v-if="data.item.status == 0">Unpublished</span> 
												<span v-else-if="data.item.status == 1">Published</span> 
												<span v-else-if="data.item.status == 2">Draft</span> 
												<span v-else>Scheduled</span> 
											</div>
										</template>									
										<template #cell(action)="data">
											<div class="d-block text-center">
												<template v-if="userData">
													<span class="px-05 cursor-pointer" @click.stop="userful_edit(data.item.id)">
														<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#8471FF"/>
														</svg>
													</span>
													<span class="px-05 cursor-pointer" @click.stop="showDeleteAction(data.item.id, data.item.title)">
														<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#DD3E47"/>
														</svg>
													</span>
												</template>
												<template v-else>
													<span class="px-05">
														<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#b1b1b1"/>
														</svg>
													</span>
													<span class="px-05">
														<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
														</svg>
													</span>
												</template>
											</div>
										</template>	
										<template #cell()="data">
											<span class="d-block text-center">{{ data.value }}</span>
										</template>	
					                	<template #empty="scope">
									      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
									    </template>
					                </b-table>
		            			</b-card>
		            			<div v-if="this.total_2 > 0" class="paging-cus relative">
									<b-pagination
								      	v-model="currentPage_2"
								      	:total-rows="total_2"
								      	:per-page="perPage_2"
								      	aria-controls="userful-table"
								      	first-number
							        	last-number
							        	class="just-center"
								    ></b-pagination>
								    <div class="limit-per">
										<select v-model="selected_2" class="cursor-pointer">
										    <option v-for="limit in limitpage_2">{{ limit }}</option>
										</select>			  
								    </div>
								</div>
		            		</b-tab>
		            	</b-tabs>
		            </b-tab> 
		            <b-tab title="Services Setup" class="tab-child tab-pt-0">	
		            	<Services ref="tab_2" :search2="getSearch" />
		            </b-tab> 
		            <b-tab title="Country Setup" class="tab-child tab-pt-0">	
		            	<Country ref="tab_3" :search2="getSearch" />
		            </b-tab> 
		            <b-tab title="Project Code" class="tab-child tab-pt-0">	
		            	<ProjectCode ref="tab_4" :search2="getSearch" />
		            </b-tab>  
					<b-tab title="Permission" class="tab-child tab-pt-0">	
		            	<Permission ref="tab_5" />
		            </b-tab>    
					              
		        </b-tabs>  
		    </b-col>
		</b-row>
	</section>	
 	
</template>

<script>
	import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

    import Services from './tabs/tabService'
    import Country from './tabs/tabCountry'
    import ProjectCode from './tabs/tabProjectCode'
	import Permission  from './tabs/tabPermission'
	import globalAdmin from '../model/globalAdmin'
	export default {
		mixins: [globalAdmin],
	  	components: {
	  		vSelect,
	  		Services,
	  		Country,
	  		ProjectCode,
			Permission
	  	},
	  	data() {
		    return {		
		    	userData: JSON.parse(localStorage.getItem('userData')),    	
			    list: [],
				permission: [],
			    selected_staff: null,
			    selected_module: null,
			    selected_action: null,
			    staff_options: [],
			    module_options: [],			    
			    staff_action: [
			    	{title: 'Created', value: "created"},
			    	{title: 'Updated', value: "updated"},
			    	{title: 'Deleted', value: "deleted"},
			    ],
			    useful_fields: [
			    	{key: 'id', label: 'UIID'},
			    	{key: 'title', label: 'Title'},
			    	{key: 'created_at', label: 'Date Added'},
			    	{key: 'status', label: 'Status'},
			    	{key: 'action', label: 'Actions'},
			    ],
			    useful: [],			    
			    boxs: [],
				boxsAccessLog: [],
		        tabIndex_info: 0,
		        tabIndex_parent: 0,
		        searchInput: null,
				searchInputAccess: null,

		        sort_user: {"field": "id","type": "asc"},
		        sort_announ: {"field": "id","type": "asc"},

		        perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10',

    			perPage_2: 10,
        		currentPage_2: 1, 
        		total_2: 0,
        		limitpage_2: ['10', '20', '30'],
    			selected_2: '10',


    			selected_access: null,
    			optionsAccess: [],

		        accessStaff_options: [],
		        accessExpert_options: [],

		        getSearch: null,
		        timer: null,
				//activity log
				total_activity_log: 0,
				perPage_activity_log: 10,
        		currentPage_activity_log: 1,
        		limitpage_activity_log: ['10', '20', '30'],
    			selected_activity_log: '10',
		    }
		},
		mounted () {
		    this.selected = '10'
		    this.selected_2 = '10'
		},
		watch: {            
			searchInput(val) {
            	this.fetchAdministrator(val)
            }, 
			searchInputAccess(val) {
				this.fetchAdministratorAccessLog(val)
			},
            selected_staff(val) {
                this.fetchAdministrator(this.searchInput) 
            },        
            selected_module(val) {                
                this.fetchAdministrator(this.searchInput) 
            }, 
            selected_action(val){
            	this.fetchAdministrator(this.searchInput) 
            },
			selected_access(val){
				this.fetchAdministratorAccessLog(this.searchInputAccess)
			},
            currentPage(val){
				this.fetchAnnouncements()
			},
			currentPage_2(val){
				this.fetchUserful()
			},

			getSearch(val) {
                this.loadDataToTab(val)            
            },           
            tabIndex_parent: function(val) {
            	this.loadDataToTab(this.getSearch) 
            },
			selected_2(val){
				if(val) {
					this.perPage_2 = val
					this.fetchUserful()
				}
			},
			selected_activity_log(val){
				if(val){
					this.perPage_activity_log = val
					this.fetchAdministrator(this.searchInput)
				}
			},
			currentPage_activity_log(val){
				this.fetchAdministrator(this.searchInput)
			}
        },
        mounted() {
            this.$nextTick(() => {
            	this.loadDataToTab(this.getSearch)
            })
        },
		created(){		
			if(this.$route.query && this.$route.query.tab) this.tabIndex_parent = Number(this.$route.query.tab)
			if(this.$route.query && this.$route.query.tab && this.$route.query.child) this.tabIndex_info = Number(this.$route.query.child)
			this.permission = this.checkPermission(this.userData,"Administration");
			// if(!this.permission.read || !this.permission.edit || !this.permission.create || !this.permission.delete){
			// 	this.$router.push({ path: `/admin/dashboard` })
			// }
			// this.fetchAnnouncements();	
			this.fetchUserful();	
			this.fetchAdministrator(this.searchInput);	
			this.fetchAdministratorAccessLog(this.searchInputAccess);
			this.fetchServicesSetup();	
			this.staff_log_list();
			this.module_log_list();
		},		
		methods: {
			searchValue(val) {
				if (this.timer) {
			        clearTimeout(this.timer);
			        this.timer = null;		        
			    }
			    this.getSearch = val
			},
			loadDataToTab(val) {
				switch (this.tabIndex_parent){
					case 2:
						this.$refs[`tab_${this.tabIndex_parent}`].currentPage = 1
						this.$refs[`tab_${this.tabIndex_parent}`].fetchList(val)
						break;
					case 3:
						this.$refs[`tab_${this.tabIndex_parent}`].currentPage = 1
						this.$refs[`tab_${this.tabIndex_parent}`].fetchItem(val)
						break;
					case 4:
						this.$refs[`tab_${this.tabIndex_parent}`].currentPage = 1
						this.$refs[`tab_${this.tabIndex_parent}`].fetchList(val)
						break;
					default:
						this.getSearch = null
				}
            },
			searchActivityLog(val){
			 	this.searchInput = val
			},
			searchAccessLog(val) {
				this.searchInputAccess = val
			},
			fetchAdministrator(searchInput){
				this.$store
				.dispatch('auth/fetchAdministrator', {
					limit: this.perPage_activity_log,	
					page: this.currentPage_activity_log,
					filter:
					{
					    "auditable_type": this.selected_module ? this.selected_module.group_name : null,
					  	"list_search": [
					    {
					      "field": "staff",
					      "keyword": this.selected_staff ? this.selected_staff.full_name : null
					    },	
					    { 
					    	"field": "event",
					    	"keyword": this.selected_action ? this.selected_action.value : null
					    }, 
					    { 
					    	"field": "search",
					    	"keyword": searchInput ? searchInput : null
					    }, 
					  ]
					}		
				})
				.then(response => {
					this.boxs = response.data.data.data
					this.total_activity_log = response.data.data.total
					// console.log('list Activity Log: ', this.boxs)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			fetchAdministratorAccessLog(searchInputAccess){
				this.$store
				.dispatch('auth/fetchAdministratorAccessLog', {
					limit: 50,	
					filter:
					{	
					  	"list_search": [
					    {
					      "field": "staff",
					      "keyword": this.selected_access ? this.selected_access.full_name : null
					    },					    
					    { 
					    	"field": "search",
					    	"keyword": searchInputAccess ? searchInputAccess : null
					    }, 
					  ]
					}		
				})
				.then(response => {
					this.boxsAccessLog = response.data.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			staff_log_list(){
				this.$store
				.dispatch('auth/fetch_staff_log_list', {
					filter: { 
						"group": "1"
					}
				})
				.then(response => {
					this.staff_options = response.data.data.data
					this.accessStaff_options = response.data.data.data
					this.optionsAccess = response.data.data.data				
				})
				.catch((error) => {
					console.log(error)
				})
			},
			module_log_list(){
				this.$store
				.dispatch('auth/fetch_module_log_list', {})
				.then(response => {
					this.module_options = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			fetchAnnouncements(){
				this.$store
				.dispatch('auth/fetchUserful', {
					limit: this.perPage,
					page: this.currentPage,
					filter:{
						"list_search": [{ "field": "type","keyword": "1"}] //type: 1 -> announcements
					},
					sort: this.sort_announ	
				})
				.then(response => {
					this.announcements = response.data.data.data
					this.total = response.data.data.total
					// console.log('Announcements', response.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			fetchUserful(){
				this.$store
				.dispatch('auth/fetchUserful', {
					limit: this.perPage_2,
					page: this.currentPage_2,
					filter:{
						"list_search": [{ "field": "type","keyword": "0"}] //type: 0 -> useful
					},
					sort: this.sort_user					
				})
				.then(response => {
					this.useful = response.data.data.data
					this.total_2 = response.data.data.total
					// console.log('useful: ', this.useful)
				})
				.catch((error) => {
					console.log(error)
				})
			},		
			showDeleteAction(id, title) {
		        this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
		          title: 'Delete',
		          size: 'sm',
		          buttonSize: 'sm',
		          okVariant: 'danger',
		          okTitle: 'Delete',
		          cancelTitle: 'Cancel',
		          footerClass: 'p-2 modal-delete',
		          hideHeaderClose: false,
		          centered: true
		        })
		          .then(value => {
		            	if(value){
		            		// if confirm yes => call api delete => toast => call list API
		            		this.deleteUserful(id, title)	  
		            	}
		          })
		          .catch(err => {
		            	this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                        	title: error.response.data.message,
	                        	variant: 'danger',
	                      	},
	                    },
	                    {
	                      position: 'top-center'
	                    })
		          })
		    },
		    deleteUserful(id, title){
		    	this.$store
				.dispatch('auth/deleteUserful', {
					id: id	
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: title + ' has been deleted!',
	                          variant: 'danger',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )
	                this.fetchUserful()
	                			
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
                      	component: ToastificationContent,
                      	props: {
                        	title: error.response.data.message,
                        	variant: 'danger',
                      	},
                    },
                    {
                      position: 'top-center'
                    })
				})
		    },
			fetchServicesSetup(){
				this.$store
				.dispatch('auth/fetchServicesSetup', {	
				})
				.then(response => {
					this.services = response.data.data
					// console.log('Services setup: ', response.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			addUserful(item){
				this.$router.push('/admin/administrations/add-userful-info');
			},
		  	userful_detail(item){
		  		this.$router.push(`/admin/administrations/userful-info/${item.id}`)
		  	},
		  	userful_edit(id){
		  		this.$router.push(`/admin/administrations/userful-info/edit/${id}`)
		  	}
		}
	};	

</script>

<style lang="scss">
	@import "@/assets/scss/_ite_tab_table.scss";

	.options_list > div{
		padding: 0 5px
	}
	.options_list .v-select{
		background-color: white;
	}
	.Vue-Toastification__container.top-center {
	    top: 20px; 
	}
	@media only screen and (min-width: 1401px){
		.options_list .vs__selected-options > input, .icon-search-wrapper input{
			height: 50px;
		}
		.date_added{
			white-space: nowrap;
		}
	}
	@media only screen and (min-width: 1200px){
		.options_list .vs__selected-options > input, .icon-search-wrapper input{
			height: 40px;
		}
	}	
	.vs__selected{
		max-width: 100%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		position: absolute;
		line-height: 40px !important;
		display: block !important;
	}
	.vs__selected-options{
		max-width: calc(100% - 55px);
	}
	.vs__dropdown-toggle{
		flex-wrap: nowrap;
	}
	.vs__actions{
		padding: 0px 6px 0 3px !important;
	}
</style>
