<template>
	<section>
		<b-card class="px-table-0 mb-3 w-30-percent-cl-2">
			<div class="text-right mr-2 my-2">
				<template v-if="userData">
		            <b-button type="button" variant="primary" class="btn-df size-18 black" style="background-color: #8471FF !important" @click="toAddService">
		                Add Country
		            </b-button>       
		        </template> 
		        <template v-else>
		        	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
		                Add Country
		            </b-button>       
		        </template>
	        </div>
	        <b-table small :fields="fields" :items="items" tbody-tr-class="size-14 table-row cursor-pointer" show-empty @row-clicked="detailCountry">
	        	<template #head(id)="data">
		    		<span>{{ data.label }}</span>
		    	</template>
		    	<template #head(name)="data">
		    		<span>{{ data.label }}</span>
		    	</template>
				<template #head()="data">
		    		<span class="d-block text-center">{{ data.label }}</span>
		    	</template>
		    	<template #cell(id)="data">
					<span>
						<span>CS</span>
						<span v-if="data.item.id <= 9">000</span><span v-else-if="data.item.id > 9 && data.item.id <= 99 ">00</span><span v-else-if="data.item.id > 99 && data.item.id <= 999 ">0</span>{{ data.item.id }}
					</span>
				</template>	
				<template #cell(created_at)="data">
					<div class="d-block text-center">
						{{ data.item.created_at | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }} 
					</div>
				</template>	
				<template #cell(status)="data">
					<div class="d-block text-center">
						<span v-if="data.item.status == 0">Inactive</span> 
						<span v-else-if="data.item.status == 1">Active</span>
					</div>
				</template>	
				<template #cell(action)="data">
					<div class="d-block text-center">
						<template v-if="userData">
							<span class="px-05 cursor-pointer" @click.stop="item_edit(data.item.id)">
								<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#8471FF"/>
								</svg>
							</span>
							<span class="px-05 cursor-pointer" @click.stop="showDeleteAction(data.item.id, data.item.name)">
								<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#DD3E47"/>
								</svg>
							</span>
						</template>
						<template v-else>
							<span class="px-05">
								<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M1.41999 18.5792C1.13948 18.5787 0.872062 18.4604 0.682993 18.2532C0.490439 18.0476 0.394758 17.7697 0.419993 17.4892L0.664993 14.7951L11.983 3.48115L15.52 7.01715L4.20499 18.3302L1.51099 18.5752C1.47999 18.5782 1.44899 18.5792 1.41999 18.5792ZM16.226 6.31015L12.69 2.77415L14.811 0.65315C14.9986 0.465373 15.2531 0.359863 15.5185 0.359863C15.7839 0.359863 16.0384 0.465373 16.226 0.65315L18.347 2.77415C18.5348 2.96172 18.6403 3.21624 18.6403 3.48165C18.6403 3.74706 18.5348 4.00158 18.347 4.18915L16.227 6.30915L16.226 6.31015Z" fill="#b1b1b1"/>
								</svg>
							</span>
							<span class="px-05">
								<svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V6C13 4.9 12.1 4 11 4H3C1.9 4 1 4.9 1 6V16ZM13 1H10.5L9.79 0.29C9.61 0.11 9.35 0 9.09 0H4.91C4.65 0 4.39 0.11 4.21 0.29L3.5 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H13C13.55 3 14 2.55 14 2C14 1.45 13.55 1 13 1Z" fill="#b1b1b1"/>
								</svg>
							</span>
						</template>
					</div>
				</template>	
	        	<template #empty="scope">
			      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
			    </template>
	        </b-table>	
		</b-card>	
		<div v-if="this.total > 0" class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	aria-controls="custom-table"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		    <div class="limit-per">
				<select v-model="selected" class="cursor-pointer">
				    <option v-for="limit in limitpage">{{ limit }}</option>
				</select>			  
		    </div>
		</div>   
	</section>
</template>

<script>
	import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
	  	components: {
	  		vSelect,
	  	},
		props: {
			search: {
				type: String,
				default: null
			}
        },
	  	data() {
		    return {	
		    	userData: JSON.parse(localStorage.getItem('userData')),	 
			    fields: [
			    	{key: 'id', label: 'CSID'},
			    	{key: 'name', label: 'Country Name'},
			    	{key: 'created_at', label: 'Date Created'},
			    	{key: 'status', label: 'Status'},
			    	{key: 'action', label: 'Actions'},
			    ],
			    items: [],

			    perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10',
		    }
		},
		watch: {  
			currentPage(val){
				this.fetchItem(this.search)
			},
			selected(val){
			if(val) {
				this.perPage = val
				this.fetchItem()
			}
        }
        },
        mounted () {
		    this.selected = '10'
		},
		created(){		
			this.fetchItem(this.search)
		},		
		methods: {
			fetchItem(search){
				if(!search) search = ""
				this.$store
				.dispatch('auth/fetchCountry', {	
					limit: this.perPage,
					page: this.currentPage,
					filter: {
						"name": search
					},
				})
				.then(response => {
					this.items = response.data.data.data
					this.total = response.data.data.total
					// console.log('Country list: ', this.items)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			toAddService(){
				this.$router.push('/admin/administrations/add-country');
			},	
			showDeleteAction(id, title){
				this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
		          title: 'Delete',
		          size: 'sm',
		          buttonSize: 'sm',
		          okVariant: 'danger',
		          okTitle: 'Delete',
				  cancelVariant: 'outline-danger',
		          cancelTitle: 'Cancel',
		          footerClass: 'p-2 modal-delete',
		          hideHeaderClose: false,
		          centered: true
		        })
		        .then(value => {
	            	if(value){
	            		this.deleteItem(id, title)	  
	            	}
		        })
		        .catch(err => {
		            // An error occurred
		        })
			},
			deleteItem(id, title){
				this.$store
				.dispatch('auth/deleteCountry', {
					id: id	
				})
				.then(response => {
					this.$toast({
	                      	component: ToastificationContent,
	                      	props: {
	                          title: title + ' has been deleted!',
	                          variant: 'danger',
	                      	},
	                	},
	                	{
	                      	position: 'top-center'
	                	}
	                )	               
	                this.fetchItem()	                			
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
							component: ToastificationContent,
							props: {
								title: error.response.data.message,
								variant: 'danger',
							},
						},
						{
							position: 'top-center'
						}
					)
				})
			},
			detailCountry(item){
				this.$router.push(`/admin/administrations/country/${item.id}`);
			},
			item_edit(id){
				this.$router.push(`/admin/administrations/country/edit/${id}`);
			}
		},

	};	

</script>