<template>
	<section>
		<b-row>
			<b-col md="12">
				<div class="relative">
					<b-tabs card class="caption-add table-custom">
						<validation-observer ref="form_rel" #default="{ invalid }">
							<div class="profile-frm mb-20" style="border-radius: 5px; margin-top: 10px">
								<b-row class="align-center">
									<b-col lg-12>
										<h2 class="text-left ml-2 mb-2">
											<strong>Permission Settings</strong>
										</h2>
									</b-col>
								</b-row>
								<div style="border-top: 1px solid #000000B2; padding-bottom: 6px;"></div>
								<div class="ize-16 black-child d-block">
									<div class="headerPermission">
										<b-row style="width: 100%; margin: 0;">
											<b-col class="px-0">
											</b-col>
											<b-col class="px-0">
												<h4 class="text-left"><strong>Admin</strong></h4>

											</b-col>
											<b-col class="px-0">
												<h4 class="text-center"><strong>Management</strong></h4>

											</b-col>
											<b-col class="px-0">
												<h4 class="text-right"><strong>Staff</strong></h4>
											</b-col>
										</b-row>
									</div>
									<div class="contenRoleModule">
										<div class="contentHeaderRoleModule">
											<b-row style="width: 100%; margin: 0;">
												<div class="title-header"><strong>Administration</strong></div>
											</b-row>
										</div>
										<div class="contentBody">
											<div class="contentBodyRoleModule">
												<b-row style="width: 100%; margin: 0;">
													<b-col class="px-0">
														<div class="text-center text-content f-w-400">
															View
														</div>
													</b-col>
													<b-col class="px-0">
														<div class="itemLeft">
															<b-form-checkbox value="accepted" unchecked-value="false"
																v-model="status" disabled>
															</b-form-checkbox>
														</div>

													</b-col>
													<b-col class="px-0">
														<div class="itemCenter">
															<b-form-checkbox value="" unchecked-value="false" disabled>
															</b-form-checkbox>
														</div>
													</b-col>
													<b-col class="px-0">
														<div class="itemRight">
															<b-form-checkbox value="" unchecked-value="false" disabled>
															</b-form-checkbox>
														</div>
													</b-col>
												</b-row>
											</div>
											<div class="contentBodyRoleModule">
												<b-row style="width: 100%; margin: 0;">
													<b-col class="px-0">
														<div class="text-center text-content f-w-400">
															Add
														</div>
													</b-col>
													<b-col class="px-0">
														<div class="itemLeft">
															<b-form-checkbox name="" value="accepted"
																unchecked-value="false" v-model="status" disabled>
															</b-form-checkbox>
														</div>

													</b-col>
													<b-col class="px-0">
														<div class="itemCenter">
															<b-form-checkbox value="accepted" unchecked-value="false"
																disabled>
															</b-form-checkbox>
														</div>
													</b-col>
													<b-col class="px-0">
														<div class="itemRight">
															<b-form-checkbox value="accepted" unchecked-value="false"
																disabled>
															</b-form-checkbox>
														</div>
													</b-col>
												</b-row>
											</div>
											<div class="contentBodyRoleModule">
												<b-row style="width: 100%; margin: 0;">
													<b-col class="px-0">
														<div class="text-center text-content f-w-400">
															Edit
														</div>
													</b-col>
													<b-col class="px-0">
														<div class="itemLeft">
															<b-form-checkbox name="" value="accepted"
																unchecked-value="false" v-model="status" disabled>
															</b-form-checkbox>
														</div>

													</b-col>
													<b-col class="px-0">
														<div class="itemCenter">
															<b-form-checkbox value="accepted" unchecked-value="false"
																disabled>
															</b-form-checkbox>
														</div>
													</b-col>
													<b-col class="px-0">
														<div class="itemRight">
															<b-form-checkbox value="accepted" unchecked-value="false"
																disabled>
															</b-form-checkbox>
														</div>
													</b-col>
												</b-row>
											</div>
											<div class="contentBodyRoleModule">
												<b-row style="width: 100%; margin: 0;">
													<b-col class="px-0">
														<div class="text-center text-content f-w-400">
															Delete
														</div>
													</b-col>
													<b-col class="px-0">
														<div class="itemLeft">
															<b-form-checkbox name="" value="accepted"
																unchecked-value="false" v-model="status" disabled>
															</b-form-checkbox>
														</div>

													</b-col>
													<b-col class="px-0">
														<div class="itemCenter">
															<b-form-checkbox value="accepted" unchecked-value="false"
																disabled>
															</b-form-checkbox>
														</div>
													</b-col>
													<b-col class="px-0">
														<div class="itemRight">
															<b-form-checkbox value="accepted" unchecked-value="false"
																disabled>
															</b-form-checkbox>
														</div>
													</b-col>
												</b-row>
											</div>
										</div>
									</div>
									<div class="contenRoleModule" v-for="module in modules" :key="module.id">
										<div class="contentHeaderRoleModule">
											<b-row style="width: 100%; margin: 0;">
												<div class="title-header"><strong>{{ module.group_name }}</strong></div>
											</b-row>
										</div>
										<div class="contentBody">
											<div class="contentBodyRoleModule">
												<b-row style="width: 100%; margin: 0;">
													<b-col class="px-0">
														<div class="text-center text-content f-w-400">
															View
														</div>
													</b-col>
													<b-col class="px-0">
														<div class="itemLeft">
															<b-form-checkbox value="accepted" unchecked-value="false"
																v-model="status" disabled>
															</b-form-checkbox>
														</div>

													</b-col>
													<b-col class="px-0">
														<div class="itemCenter">
															<b-form-checkbox name=""
																v-on:change="checkBoxPermissionOnChane(module.id, 'Management', 'read', permissions[module.slug]['Management']['read'])"
																v-model="permissions[module.slug]['Management']['read']"
																:disabled="isDisabled">
															</b-form-checkbox>
														</div>
													</b-col>
													<b-col class="px-0">
														<div class="itemRight">
															<b-form-checkbox
																v-on:change="checkBoxPermissionOnChane(module.id, 'Staff', 'read', permissions[module.slug]['Staff']['read'])"
																v-model="permissions[module.slug]['Staff']['read']"
																:disabled="isDisabled">
															</b-form-checkbox>
														</div>
													</b-col>
												</b-row>
											</div>
											<template v-if="(module.group_name !== 'Activity')">
												<div v-if="(module.group_name !== 'TVET Expert Application')" class="contentBodyRoleModule">
													<b-row style="width: 100%; margin: 0;">
														<b-col class="px-0">
															<div class="text-center text-content f-w-400">
																Add
															</div>
														</b-col>
														<b-col class="px-0">
															<div class="itemLeft">
																<b-form-checkbox name="" value="accepted"
																	unchecked-value="false" v-model="status" disabled>
																</b-form-checkbox>
															</div>

														</b-col>
														<b-col class="px-0">
															<div class="itemCenter">
																<b-form-checkbox
																	v-on:change="checkBoxPermissionOnChane(module.id, 'Management', 'create', permissions[module.slug]['Management']['create'])"
																	v-model="permissions[module.slug]['Management']['create']"
																	:disabled="isDisabled">
																</b-form-checkbox>
															</div>
														</b-col>
														<b-col class="px-0">
															<div class="itemRight">
																<b-form-checkbox
																	v-on:change="checkBoxPermissionOnChane(module.id, 'Staff', 'create', permissions[module.slug]['Staff']['create'])"
																	v-model="permissions[module.slug]['Staff']['create']"
																	:disabled="isDisabled">
																</b-form-checkbox>
															</div>
														</b-col>
													</b-row>
												</div>
												<div class="contentBodyRoleModule">
													<b-row style="width: 100%; margin: 0;">
														<b-col class="px-0">
															<div class="text-center text-content f-w-400">
																Edit
															</div>
														</b-col>
														<b-col class="px-0">
															<div class="itemLeft">
																<b-form-checkbox name="" value="accepted"
																	unchecked-value="false" v-model="status" disabled>
																</b-form-checkbox>
															</div>

														</b-col>
														<b-col class="px-0">
															<div class="itemCenter">
																<b-form-checkbox
																	v-on:change="checkBoxPermissionOnChane(module.id, 'Management', 'edit', permissions[module.slug]['Management']['edit'])"
																	v-model="permissions[module.slug]['Management']['edit']"
																	:disabled="isDisabled">
																</b-form-checkbox>
															</div>
														</b-col>
														<b-col class="px-0">
															<div class="itemRight">
																<b-form-checkbox
																	v-on:change="checkBoxPermissionOnChane(module.id, 'Staff', 'edit', permissions[module.slug]['Staff']['edit'])"
																	v-model="permissions[module.slug]['Staff']['edit']"
																	:disabled="isDisabled">
																</b-form-checkbox>
															</div>
														</b-col>
													</b-row>
												</div>
												<div class="contentBodyRoleModule">
													<b-row style="width: 100%; margin: 0;">
														<b-col class="px-0">
															<div class="text-center text-content f-w-400">
																Delete
															</div>
														</b-col>
														<b-col class="px-0">
															<div class="itemLeft">
																<b-form-checkbox name="" value="accepted"
																	unchecked-value="false" v-model="status" disabled>
																</b-form-checkbox>
															</div>

														</b-col>
														<b-col class="px-0">
															<div class="itemCenter">
																<b-form-checkbox
																	v-on:change="checkBoxPermissionOnChane(module.id, 'Management', 'delete', permissions[module.slug]['Management']['delete'])"
																	v-model="permissions[module.slug]['Management']['delete']"
																	:disabled="isDisabled">
																</b-form-checkbox>
															</div>
														</b-col>
														<b-col class="px-0">
															<div class="itemRight">
																<b-form-checkbox
																	v-on:change="checkBoxPermissionOnChane(module.id, 'Staff', 'delete', permissions[module.slug]['Staff']['delete'])"
																	v-model="permissions[module.slug]['Staff']['delete']"
																	:disabled="isDisabled">
																</b-form-checkbox>
															</div>
														</b-col>
													</b-row>
												</div>
											</template>
										</div>
									</div>
								</div>
							</div>
						</validation-observer>
					</b-tabs>
				</div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
	components: {
		vSelect,
		ValidationProvider,
		ValidationObserver,
	},
	setup() { },
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			modules: [],
			permissions: [],
			isDisabled: true,
			status: 'accepted',
		}
	},
	created() {
		this.getListPermissions()
		this.getListModule()
		this.isDisabled = !(this.userData.role.role_id == 1)
	},
	methods: {
		checkBoxPermissionOnChane(module_id, role_name, action_name, type) {
			let fields = {
				module_id: module_id,
				role_name: role_name,
				action_name: action_name,
				type: (type === 'true' || type),
			}
			this.postDataPermission(fields)

		},
		postDataPermission(fields) {
			this.$store
				.dispatch('auth/updatedPermission', fields)
				.then(response => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: response.data.message,
							variant: 'success',
						},
					},
						{
							position: 'top-center'
						}
					)
					this.getListPermissions();
				})
				.catch((error) => {
					console.log(error)
					this.$toast({
						component: ToastificationContent,
						props: {
							title: error.response.data.message,
							variant: 'danger',
						},
					},
						{
							position: 'top-center'
						}
					)
				})
		},
		getListPermissions() {
			this.$store
				.dispatch('auth/fetch_module_permission')
				.then(response => {
					this.permissions = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		getListModule() {
			this.$store
				.dispatch('auth/fetch_list_module_permission')
				.then(response => {
					this.modules = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
		}
	},
};

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "@/assets/scss/_ite_tab_table.scss";

.Vue-Toastification__container.top-center {
	top: 20px;
}

@media only screen and (min-width: 1401px) {

	.options_list .vs__selected-options>input,
	.icon-search-wrapper input {
		height: 50px;
	}
}

@media only screen and (min-width: 1200px) {

	.options_list .vs__selected-options>input,
	.icon-search-wrapper input {
		height: 40px;
	}
}

.title-header {
	font-size: 20px;
}

.text-content {
	font-size: 20px;
}

.headerPermission {
	padding: 14px;
	background: #E7E7E7;
	border: 1px solid #F6F6F6;
}

.contentHeaderRoleModule {
	padding: 0 14px;
	padding-top: 14px;
	padding-bottom: 14px;
	background: #F6F6F6;
	border: 1px solid #616161;
}

.contenRoleModule {
	padding-top: 4px;
}

.contentBodyRoleModule {
	margin-top: 4px;
	padding: 14px;
	border: 0.3px solid #F6F6F6;
}

.itemCenter {
	display: flex;
	justify-content: center;
}

.itemRight {
	padding-right: 14px;
	display: flex;
	justify-content: end;
}

.itemLeft {
	padding-left: 14px;
	display: flex;
	justify-content: start;
}

.f-w-400 {
	font-weight: 400 !important;
}</style>