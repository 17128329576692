import { render, staticRenderFns } from "./tabCountry.vue?vue&type=template&id=180a8a68&"
import script from "./tabCountry.vue?vue&type=script&lang=js&"
export * from "./tabCountry.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports